<template>
  <v-app>
    <v-container>
      <BatteryPieChart />
    </v-container>
    <v-container>
      <TemperaturePieChart />
    </v-container>
  </v-app>
</template>

<script>
import BatteryPieChart from "@/components/BatteryPieChart.vue";
import TemperaturePieChart from "@/components/TemperaturePieChart.vue";

export default {
  name: "App",
  components: {
    BatteryPieChart,
    TemperaturePieChart,
  },
};
</script>
